import Navbar from "./navbar"
import Upload from "./upload"

const { Box } = require("@mui/material")

const Admin = ()=>{
    return(
        <>
        <Navbar/>
        <Upload/>
        </>
    )
    
}

export default Admin
